import React, {  useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import URLS from './URL_DB.json'

function getUurlTarget(url){
  let item = URLS.filter(x=>x.url === url); 
  if (item && item.length > 0) {
    return item[0].target
  }
  return null;
}

function App() {
  useEffect(() => {
    let target = getUurlTarget(window.location.pathname);
    if (target)   window.location = target;
  },[]);
  return (
    <div className="App">Loading...</div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);